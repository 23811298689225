import React from 'react'
import Layout from '../containers/layout'
import Container from '../components/container'
import Contact from '../components/pages/contact'
import SEO from '../components/seo'
import Breadcrumb from '../components/layout/breadcrumb'

const CalendarPage = props => {
  let navItemStack = [{ name: 'تواصل معنا', slug: 'success' }]

  return (
    <Layout>
      <SEO title="تواصل معنا" />
      <Breadcrumb currentNavItems={navItemStack} />
      <Container>
        <p>
          <strong>شكرا</strong> لتواصلك
        </p>
      </Container>
    </Layout>
  )
}
export default CalendarPage
